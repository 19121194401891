import 'regenerator-runtime/runtime'
import api from "@/services/apiService"

class RecordService {

  get(date, accountId = null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'record/' + date + (accountId?`/${accountId}`:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  save(data, accountId = null) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'record' + (accountId?`/${accountId}`:''), data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  saveOtherItem(data, accountId = null) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'record/other-item' + (accountId?`/${accountId}`:''), data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  update(id, data, accountId = null) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'record/' + id + (accountId?`/${accountId}`:''), data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  delete(id, accountId = null) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'record/' + id + (accountId?`/${accountId}`:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  saveSummary(data, accountId = null) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'record/summary' + (accountId?`/${accountId}`:''), data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

  saveAccrual(itemId, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'record/accrual/' + itemId, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

}

export default new RecordService()
