<template>
  <div id="accrual-record" class="container">

    <h3>รายการลูกค้าค้าง</h3>
    <CardDateFrom :branches="[]" @selected="searchData" />

    <div v-if="isManager" class="alert alert-info">
      <b-form-group v-if="branchOptions.length" class="mb-1" label="เลือกดูทีม:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="branchFilters"
          :options="branchOptions"
          :aria-describedby="ariaDescribedby"
          name="branchFilters"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="d-flex justify-content-between">
      <span class="text-primary">ข้อมูลวันที่ {{dataDateText}}</span>
      <b-form-group class="mb-1" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="filterStatus"
          :options="statusOptions"
          :aria-describedby="ariaDescribedby"
          name="filterStatus"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="card">
      <table class="table table-sm table-bordered mb-0">
        <thead>
          <tr>
            <th style="width: 15%;" class="text-center">ทีม</th>
            <th class="text-center">วันที่</th>
            <th style="width: 35%;" class="text-center">รายละเอียด</th>
            <th style="width: 10%;" class="text-center">จำนวน</th>
            <th style="width: 15%;" class="text-center">สถานะ</th>
            <th style="width: 10%;" class="text-center">บันทึก</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items">
            <td class="text-center">{{ branchName(item) }}</td>
            <td class="text-center">{{item.date}}</td>
            <td>{{item.description}} <div class="text-info small">โดย: {{item.logs.by}} เวลา: {{item.logs.date}}</div></td>
            <td class="text-right"><span class="text-info" v-html="numberFormat(item.amount.number)"></span></td>
            <td class="text-center">
              <span class="badge badge-pill" :class="[isPaid(item)?'badge-success':'badge-danger']">{{isPaid(item)?'จ่ายแล้ว':'ยังไม่จ่าย'}}</span>
            </td>
            <td class="text-center">
              <button class="btn btn-sm btn-link" @click="editItem(item)">บันทึก</button>
            </td>
          </tr>
        </tbody>
        <tbody v-if="!items.length">
          <tr>
            <td colspan="6" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="items.length > 0" style="border-top: 3px double #ccc;">
          <tr style="font-size: 120%">
            <th class="text-center" colspan="3">รวม</th>
            <th class="text-right text-info"><span v-html="numberFormat(total)"></span></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <AccrualModal :data="viewItem" :is-show="isShowModal" @close="modalClosed" />

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </div>
</template>
<script>
import AppConfig from '@/configs/app.config.js'
import CardDateFrom from '@/views/components/CardDateFrom'
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ReportService from '@/services/ReportService'
import AccrualModal from './record/components/AccrualModal'

export default {
  name: 'BranchAccrualRecord',
  components: {
    CardDateFrom,
    Loading,
    AccrualModal
  },
  data() {
    return {
      isLoading: false,
      dataDate: null,
      successDataDate: null,
      selectedBranch: null,
      data: [],
      viewItem: null,
      isShowModal: false,
      filterStatus: ['paid', 'no'],
      statusOptions: [
        { text: 'จ่ายแล้ว', value: 'paid' },
        { text: 'ยังไม่จ่าย', value: 'no' }
      ],
      branchFilters: [],
    }
  },
  computed: {
    isManager() {
      const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
      return LoggedInData?.role === 'Manager'
    },
    dataDateText() {
      if(!this.successDataDate) {
        return '-'
      }

      const diff = moment(this.dataDate.end).diff(this.dataDate.start, 'd') + 1

      if(this.successDataDate.start === this.successDataDate.end) {
        return `${this.successDataDate.start}`
      }else{
        return `${this.successDataDate.start} ถึง ${this.successDataDate.end}`
      }
    },
    items() {
      return (this.data?.AccrualItems || [])
      .filter((item)=>{
        if(!this.branchFilters.length)
          return true

        return this.branchFilters.includes(item.owners.accountId)
      })
      .filter((item)=>{
        const status = item?.logs?.status || 'no'
        return this.filterStatus.includes(status)
      })
    },
    Branchs() {
      return this.data?.Branchs || []
    },
    total() {
      return this.items.reduce((total, item)=>{
        return total + Math.abs(parseFloat(item.amount.number))
      }, 0)
    },
    branchOptions() {
      return this.Branchs.map((branch)=>{
        return {
          text: branch.name,
          value: branch._id
        }
      })
    },
  },
  methods: {
    searchData(params) {
      console.log(params)
      this.dataDate = params?.date
      this.selectedBranch = params?.branch
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      ReportService.getAccruals(AppConfig.accrual.itemId, this.dataDate, this.selectedBranch)
      .then((response)=>{
        if(response.status === 'success') {
          this.successDataDate = this.dataDate
          this.data = response.data
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    numberFormat(number, dec=2) {
      if(!number || number==='-')
        return '-'

      number = Math.abs(parseFloat(number)).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

      return numberText
    },
    editItem(item) {
      this.viewItem = item
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadData()
      }
    },
    isPaid(item) {
      return item?.logs?.status==='paid'
    },
    branchName(item) {
      const branch = this.Branchs.find((branch)=>{
        return item.owners.accountId === branch._id
      })

      return branch?.name
    }
  },
  mounted() {
    console.log('mounted')
    this.isShowModal = false
  }
}
</script>
<style lang="scss" scoped>
#accrual-record {
  position: relative;
  padding-bottom: 50px;

  .table {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
