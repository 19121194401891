<template>
  <b-modal
    id="accrual-madal"
    ref="accrual-madal"
    no-close-on-backdrop
    hide-footer
    title="บันทึกรายการลูกค้าค้าง"
    @show="resetModal"
    @hidden="resetModal"
  >

    <div class="p-3">

      <div class="mb-2">
        <span class="small">โดย: {{by}} เวลา: {{date}}</span>
        <div v-if="description" class="p-1 alert alert-info mb-0">
          {{description}}
        </div>
      </div>

      <div class="mb-2" v-for="(item, key) in items" :key="key">
        <span v-if="item.status" class="badge badge-pill badge-success mr-2">จ่ายแล้ว</span>
        <span class="small">โดย: {{item.by}} เวลา: {{item.date}}</span>
        <div v-if="item.message || item.image" class="p-1 alert alert-secondary mb-0">
          <div>{{item.message}}</div>
          <div class="text-center mt-3 mb-2" v-if="item.image">
            <a :href="item.image" target="_blank">
              <div style="width: 90%; margin: 0 auto; padding: 5px; border: 1px solid #0c5460; display: block; background: #FFF; border-radius: 5px;">
                <img :src="item.image" style="max-width: 100%;">
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <span>บันทึกข้อความ</span>
        <div class="mb-2">
          <b-form-textarea
            v-model="message"
            placeholder="ใส่รายละเอียด..."
          ></b-form-textarea>
        </div>
        <b-form-checkbox v-model="isPaid" name="check-button" switch>
          <span>จ่ายแล้ว</span>
        </b-form-checkbox>
        <b-form-file
          v-if="isPaid"
          v-model="file"
          ref="inputFile"
          class="mt-2"
          size="sm"
          placeholder="แนบสลิป"
          browse-text="เลือกไฟล์"
          accept="image/jpeg, image/png"
        ></b-form-file>
      </div>

    </div>

    <div class="d-flex justify-content-end p-2">
      <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
      <b-button :disabled="isProcess" type="submit" variant="primary" size="sm" @click="handleSubmit">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
    </div>
  </b-modal>
</template>
<script>
import Swal from 'sweetalert2'
import RecordService from '@/services/RecordService'

export default {
  name: 'AccrualModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {
        description: null
      }
    }
  },
  data() {
    return {
      isProcess: false,
      needReload: false,
      message: null,
      isPaid: false,
      file: null
    }
  },
  computed: {
    by() {
      return this.data?.logs.by
    },
    date() {
      return this.data?.logs.date
    },
    description() {
      return this.data?.description
    },
    items() {
      return this.data?.logs?.items || []
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {
      this.message = null
      this.needReload = false
      this.isProcess = false
      this.isPaid = null
    },
    showModal() {
      this.$refs['accrual-madal'].show()
    },
    hideModal() {
      this.$refs['accrual-madal'].hide()
    },
    handleSubmit() {
      console.log(this.file)
      if(this.message || this.isPaid) {
        this.save()
      }else{
        Swal.fire({
          title: 'กรุณาระบุรายละเอียด!',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }
    },
    save() {
      this.isProcess = true
      let formData = new FormData()

      if(this.message) {
        formData.append('message', this.message)
      }

      if(this.isPaid) {
        formData.append('status', this.isPaid)
      }

      if(this.file) {
        formData.append('image', this.file)
      }

      RecordService.saveAccrual(this.data._id, formData)
      .then((response)=>{
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#accrual-madal {

  input[type=text] {
    color: blue;
  }

  .modal-dialog {
    .modal-body {
      padding: 0;
    }
  }
}
</style>
